if (module.hot) {
  module.hot.accept();
}

import $ from 'jquery';
window.jQuery = $;
window.$ = $;
import 'waypoints/lib/noframework.waypoints.min.js';

window.onload = function() {
  setTimeout(function(){$('body').removeClass('prevent-transition');}, 100);
}

$(document).ready(function(){

  if ($('.iframeCode').length > 0){

    // select the target node
    var target2 = document.querySelector('#mce-success-response');

    // create an observer instance
    var observer2 = new MutationObserver(function(mutations) {
      mutations.forEach(function(mutation) {
        if (target2.style.display === 'block') {
          $('body').addClass('show-message');
        }
      });
    });

    // configuration of the observer:
    var config = { attributes: true, childList: true, characterData: true };

    // pass in the target node, as well as the observer options
    observer2.observe(target2, config);

  } else {

    $('#email-button').click(function(){
      var mail = document.createElement("a");
      mail.href = "mailto:info@scienceworks.us";
      mail.target = '_blank';
      mail.click();
    })

    $('.see-more-button').click(function(e){
      $(this).closest('.see-more').toggleClass('show-more');
    })

    let ieAgent = !!window.MSInputMethodContext && !!document.documentMode;;
    if(ieAgent){
      $('html').addClass('IE11')
    }

    $('.partners-show-more').click(function(){
      $('.partners-section').toggleClass('show')
    })

    $('.video-frame').click(function(){
      if (this.ytPlayer){
        $(this).addClass('play-video');
        this.ytPlayer.playVideo();
      }
    })

    $('.subscribe-scroll').click(function(){
      $('.header-subscribe .row').toggleClass('scroll')
    })

    var navDropdown = new Waypoint({
      element: $('.hero-section')[0],
      handler: function(direction) {
        if(direction == 'down'){
          $('header').addClass('trigger');
        } else {
          $('header').removeClass('trigger');
        }
      },
      offset: '-5%'
    })

    // select the target node
    var target = document.querySelector('.header-subscribe #mce-success-response');

    // create an observer instance
    var observer = new MutationObserver(function(mutations) {
      mutations.forEach(function(mutation) {
        if (target.style.display === 'block') {
          $('.header-subscribe').addClass('show-message');
        }
      });
    });

    // configuration of the observer:
    var config = { attributes: true, childList: true, characterData: true };

    // pass in the target node, as well as the observer options
    observer.observe(target, config);

    if(getCookie('SWCookieAccept')){
      $('.cookie-banner').hide();
    }

    $('.cookie-banner button').click(function(){
      document.cookie = "SWCookieAccept=1; path=/";
      $('.cookie-banner').hide();
    })

    $('.media-header-filters a.filter').click(function(){
      $(this).toggleClass('filtered');
      $('#curator-feed-default-feed-layout').toggleClass(this.getAttribute('filter'));
    })


    $.getScript("https://www.youtube.com/iframe_api", function() {
      window.YT.ready(function() {
        $.each( $('.video-frame'), function(i,v){
          var videoTarget = $(v).find('.video-target');
          if (videoTarget.length != 0){
            var embedCode = videoTarget[0].getAttribute('id');
            v.ytPlayer = new YT.Player(embedCode, {
              videoId: embedCode,
              playerVars: {
                rel: 0
              }
            });
          }
        })
      })
    });


  }


})



function getCookie(name) {
    // Split cookie string and get all individual name=value pairs in an array
    var cookieArr = document.cookie.split(";");

    // Loop through the array elements
    for(var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");

        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if(name == cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }

    // Return null if not found
    return null;
}
